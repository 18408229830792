import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Input, LoadingOverlay } from '@mantine/core';
import { RiAddLine, RiCheckLine, RiCloseLine, RiMenuLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const params = useParams();

    const [loading, setLoading] = useState(false);

    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);

    useEffect(() => {
        handleGetEntity();
    }, [])

    const handleGetEntity = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get(`/orders/${params?.id}`);

            console.log(results?.order)

            setOrder(results?.order || {})
            setProducts(results?.order_products || [])

        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = async (id) => {
        try {
            setLoading(true)
            await axios.post(`/orders/${id}`);
            handleGetEntity();
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>SİPARİŞ YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SİPARİŞ BİLGİLERİ</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/orders"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM SİPARİŞLER</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Firma Adı:</span>
                                <Input value={order?.title} size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Sipariş Tarihi:</span>
                                <Input value={order?.created_at} size="md" />
                            </div>
                        </div>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SİPARİŞ İÇERİĞİ</h1>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            {products?.length > 0 ? products.map(p => (
                                <div className='col-span-12 grid grid-cols-12 gap-x-4 gap-y-3'>
                                    <div className='col-span-1'>
                                        <span className='font-light mb-1 block'>#</span>
                                        <Input value={p.id} size="sm" />
                                    </div>
                                    <div className='col-span-8'>
                                        <span className='font-light mb-1 block'>Ürün Adı:</span>
                                        <Input value={p.title} size="sm" />
                                    </div>
                                    <div className='col-span-2'>
                                        <span className='font-light mb-1 block'>Miktar:</span>
                                        <Input value={`${p.qty} Ad.`} size="sm" />
                                    </div>
                                    <div className='col-span-1'>
                                        <span className='font-light mb-1 block'>&nbsp;</span>
                                        <button onClick={() => handleUpdate(p.id)} className={`w-full h-9 ${p?.is_shipmentable ? "bg-green-500 text-white" : "bg-gray-200 text-slate-900"} flex flex-row items-center justify-center text-sm rounded`}>
                                            {p?.is_shipmentable ? <RiCheckLine /> : <RiCloseLine />}
                                        </button>
                                    </div>
                                </div>
                            )) : <span></span>}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}