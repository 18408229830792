import { useEffect, useMemo, useState } from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import moment from "moment";

import { RiArrowRightLine, RiArrowLeftLine, RiSkipBackLine, RiSkipForwardLine } from "react-icons/ri";
import { Input } from '@mantine/core';

const DataTable = ({ columns, data, paginate = false, searchable = false, style = false, sort }) => {

    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState(sort ? [{ "desc": true, "id": "pressure" }] : [])
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 20 })

    const pagination = useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination: paginate ? pagination : {},
            globalFilter,
        },
        onColumnFiltersChange: setGlobalFilter,
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: paginate ? getPaginationRowModel() : () => { },
        onPaginationChange: paginate ? setPagination : () => { },
    });

    function limitter(string = '', limit = 0) {
        return string
    }

    return (
        <>
            {searchable && <div className='p-6'>
                <span className='block pb-1 font-light text-base'>Anahtar Kelime:</span>
                <Input
                    autoFocus={true}
                    value={globalFilter}
                    onChange={value => setGlobalFilter(String(value.target.value))}
                    size="md"
                    placeholder="Aramak için birşeyler yazın..."
                />
            </div>}

            {table.getRowModel().rows?.length > 0 ? (
                <div>
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup, index) => (
                                <tr key={index}>
                                    {headerGroup.headers.map((header, index) => (
                                        <th key={index} className={`text-left ${style ? "bg-slate-50" : "bg-gray-50"} sticky top-0 border-b border-slate-50 px-6 py-5 text-slate-600 font-bold tracking-wider text-xs truncate`} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : '',
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: ' 🔼',
                                                            desc: ' 🔽',
                                                        }[header.column.getIsSorted()] ?? null}
                                                    </div>
                                                </>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row, i) => {
                                return (
                                    <tr key={i} className={!style && "hover:bg-slate-50"}>
                                        {row.getVisibleCells().map((cell, i) => {
                                            const defs = cell?.column?.columnDef;
                                            if (defs.progress) {
                                                let progress = ((cell.getValue() * 100) / 300)
                                                return (
                                                    <td key={i} className={`border-dashed border-t border-slate-50 ${style ? "py-2" : "py-4"} px-6 text-sm w-auto ${defs.full && "w-full"}`}>
                                                        <div className='w-full h-6 rounded bg-slate-100'>
                                                            <span style={{ width: `${progress}%` }} className={`h-full ${cell.row.original.boundary > progress ? "bg-red-400" : "bg-green-400"} flex flex-row items-center justify-start pl-2`}>{progress?.toFixed(1)}%</span>
                                                        </div>
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td key={i} className={`border-dashed border-t border-slate-50 ${style ? "py-2" : "py-4"} px-6 text-sm w-auto ${defs.truncate && "truncate"} ${defs.full && "w-full"}`}>
                                                    {defs.date ? moment(cell.getValue()).fromNow() : defs.limit ? limitter(cell.getValue(), defs.limit) : flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {(table.getCanNextPage() || table.getCanPreviousPage()) && (
                        <div className="flex justify-center items-center gap-2 border-t border-t-slate-100 py-4">
                            <button
                                className="border rounded p-1 disabled:bg-slate-50"
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <RiSkipBackLine />
                            </button>
                            <button
                                className="border rounded p-1 disabled:bg-slate-50"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <RiArrowLeftLine />
                            </button>
                            <button
                                className="border rounded p-1 disabled:bg-slate-50"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <RiArrowRightLine />
                            </button>
                            <button
                                className="border rounded p-1 disabled:bg-slate-50"
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                <RiSkipForwardLine />
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className='p-6 bg-white text-sm text-center'>
                    <h1>Hiç içerik bulunamadı!</h1>
                </div>
            )}

        </>
    )

}

export default DataTable